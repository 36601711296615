/** dbKey representations of books of the bible */
export enum BOOKS {
  GENESIS = 'Genesis',
  EXODUS = 'Exodus',
  LEVITICUS = 'Leviticus',
  NUMBERS = 'Numbers',
  DEUTERONOMY = 'Deuteronomy',
  JOSHUA = 'Joshua',
  JUDGES = 'Judges',
  RUTH = 'Ruth',
  FIRST_SAMUEL = '1 Samuel',
  SECOND_SAMUEL = '2 Samuel',
  FIRST_KINGS = '1 Kings',
  SECOND_KINGS = '2 Kings',
  FIRST_CHRONICLES = '1 Chronicles',
  SECOND_CHRONICLES = '2 Chronicles',
  EZRA = 'Ezra',
  NEHEMIAH = "Nehemiah",
  TOBIT = "Tobit",
  JUDITH = "Judith",
  ESTHER = "Esther",
  FIRST_MACCABEES = "1 Maccabees",
  SECOND_MACCABEES = "2 Maccabees",
  JOB = "Job",
  PSALMS = "Psalms",
  PROVERBS = "Proverbs",
  ECCLESIASTES = "Ecclesiastes",
  SONG_OF_SONGS = "Song of Solomon",
  WISDOM = "Wisdom",
  SIRACH = "Sirach",
  ISAIAH = "Isaiah",
  JEREMIAH = "Jeremiah",
  LAMENTATIONS = "Lamentations",
  BARUCH = "Baruch",
  EZEKIEL = "Ezekiel",
  DANIEL = "Daniel",
  HOSEA = "Hosea",
  JOEL = "Joel",
  AMOS = "Amos",
  OBADIAH = "Obadiah",
  JONAH = "Jonah",
  MICAH = "Micah",
  NAHUM = "Nahum",
  HABAKKUK = "Habakkuk",
  ZEPHANIAH = "Zephaniah",
  HAGGAI = "Haggai",
  ZECHARIAH = "Zechariah",
  MALACHI = "Malachi",
  MATTHEW = "Matthew",
  MARK = "Mark",
  LUKE = "Luke",
  JOHN = "John",
  ACTS_OF_THE_APOSTLES = "Acts",
  ROMANS = "Romans",
  FIRST_CORINTHIANS = "1 Corinthians",
  SECOND_CORINTHIANS = "2 Corinthians",
  GALATIANS = "Galatians",
  EPHESIANS = "Ephesians",
  PHILIPPIANS = "Philippians",
  COLOSSIANS = "Colossians",
  FIRST_THESSALONIANS = "1 Thessalonians",
  SECOND_THESSALONIANS = "2 Thessalonians",
  FIRST_TIMOTHY = "1 Timothy",
  SECOND_TIMOTHY = "2 Timothy",
  TITUS = "Titus",
  PHILEMON = "Philemon",
  HEBREWS = "Hebrews",
  JAMES = "James",
  FIRST_PETER = "1 Peter",
  SECOND_PETER = "2 Peter",
  FIRST_JOHN = "1 John",
  SECOND_JOHN = "2 John",
  THIRD_JOHN = "3 John",
  JUDE = "Jude",
  REVELATION = "Revelation",
  CANTICLES = "Canticles",
  INVALID = "Invalid",
}

/** dbKey representations of versions of the bible. A version is a translation, but as there may be 
 * multiple prints of a translation with different notes or slight variations we call them 
 * "VERSIONS".
 */
export enum VERSIONS {
  KJV = 'KJV',
}