import React from "react";
import "./components.css"

export class Navbar extends React.Component{
  render() {
    return (
      <div className="Navbar-header">
        <h1>Bible FYI</h1>
      </div>
    )
  }
}